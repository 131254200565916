import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Bounty from './style'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
export default function BountySection(){
return(
<Bounty>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xl-8 col-lg-9 col-md-11 text-center">
        <Bounty.Box>
          <Bounty.Title as="h3">We-Connect Security Bounty Program</Bounty.Title>
        </Bounty.Box>
      </Col>
    </Row>
    <Row className="justify-content-center">
      <Col className="col-xxl-9 col-xl-10 col-lg-10">
        <Bounty.Content>
          
          <Bounty.ContentBox>
            <Bounty.Text>At We-Connect, user data security is paramount. We build our software and infrastructure with this goal at the forefront. That's why we're excited to welcome the security community to help us identify and fix vulnerabilities.</Bounty.Text>
            <Bounty.Text>We encourage everyone to test the security of our platform. If you want to join, here are some guidelines:</Bounty.Text>
          </Bounty.ContentBox>
          <Bounty.ContentBox>
            <Bounty.Text>• Follow the Terms of Service (ToS) and avoid disrupting our service or using automated testing tools.</Bounty.Text>
            <Bounty.Text>• Only interact with your account and avoid any actions affecting other users' data.</Bounty.Text>
            <Bounty.Text>• If you discover a security vulnerability, report it immediately.</Bounty.Text>
            <Bounty.Text>• Don't disclose vulnerability details until we've fixed the issue.</Bounty.Text>
            <Bounty.Text>• We award a single bounty per vulnerability. The first valid report received qualifies for the reward.</Bounty.Text>
          </Bounty.ContentBox>
          <Bounty.ContentBox>
            <Bounty.TitleSmall as="h3">What We're Looking For</Bounty.TitleSmall>
            <Bounty.Text>We're interested in any security exploit, but we offer increased rewards for the following:</Bounty.Text>
            <Bounty.Text>• <b>Tampering User Data</b>: This includes extracting or modifying another user's information (e.g., leads). Simply proving an account exists doesn't qualify.</Bounty.Text>
            <Bounty.Text>• <b>API Security</b>: Successfully exceeding your API request quota or bypass authentication entirely.</Bounty.Text>
            <Bounty.Text>• <b>Cross-Site Scripting (XSS)</b></Bounty.Text>
            <Bounty.Text>• <b>Server-Side Code Execution</b></Bounty.Text>
          </Bounty.ContentBox>
          <Bounty.ContentBox>
            <Bounty.TitleSmall as="h3">What We’re Not Looking For:</Bounty.TitleSmall>
            <Bounty.Text>• This program focuses on security vulnerabilities, not regular bugs. Report those to our support team. </Bounty.Text>
            <Bounty.Text>• Denial-of-Service (DoS) or brute-force attacks</Bounty.Text>
            <Bounty.Text>• Mixed-content scripts</Bounty.Text>
            <Bounty.Text>• Social engineering attempts</Bounty.Text>
            <Bounty.Text>• Theoretical vulnerabilities</Bounty.Text>
            <Bounty.Text>• "Best practice" issues (e.g., common HTTP headers, link expiration, email validation, or password policy)</Bounty.Text>
            <Bounty.Text>• Known theoretical vulnerabilities deemed low-risk (e.g., non-expiring session cookie with HTTPS and HSTS preloading)</Bounty.Text>
            <Bounty.Text>• Accessing device or location information from team members</Bounty.Text>
          </Bounty.ContentBox>
          <Bounty.ContentBox>
            <Bounty.TitleSmall as="h3">Reward System</Bounty.TitleSmall>
            
            
            <Bounty.Text>Our flexible reward system doesn't have a fixed limit. We value the severity and creativity of your findings. Rewards depend solely on the vulnerability's impact and are distributed via PayPal after the fix is deployed. Please note, PayPal transaction fees are deducted from the awarded amount.</Bounty.Text>
          </Bounty.ContentBox>
          <Bounty.ContentBox>
            <Bounty.TitleSmall as="h3">Reporting a Vulnerability</Bounty.TitleSmall>
            <Bounty.Text><AnchorLink to="#">Submit your report here</AnchorLink>. We respond to all submissions within a few days. Once the patch is live, you'll receive your bounty through PayPal.</Bounty.Text>
            <Bounty.Text>If you have any questions regarding the program, please contact us at <a href="mailto: security@we-connect.io">security@we-connect.io</a>.</Bounty.Text>
          </Bounty.ContentBox>
        </Bounty.Content>
      </Col>
    </Row>
  </Container>
</Bounty>

)
}